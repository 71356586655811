import React from "react"
import Link from "gatsby-link"
import { graphql } from "gatsby"
import styles from "../styles/strongSlant.module.css"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import BreadCrumb from "../components/BreadCrumb"

export default data => {
	return (
		<Layout>
			<Helmet>
				<body className={styles.fnw} />
			</Helmet>
			<div className={styles.basicContainer}>
				<BreadCrumb
					style={{
						display: "block",
						float: "left",
						position: "absolute",
						top: "calc(3rem - 34px)",
						paddingLeft: "20px",
					}}
				>
					Contact Me
				</BreadCrumb>
				<h1>Contact Me</h1>
				<div>
					Email me at <span>jordan</span>
					<span> (at) </span>
					<span>j</span>
					<span>gasspoore</span>.com
				</div>
			</div>
		</Layout>
	)
}
